import type { Contract } from 'validata';
import { isString } from 'validata';
import { isUlid } from '../ulid.js';

export interface TempFile {
  extension: string;
  fileId: string;
  uploadId: string;
  mimeType: string;
}

export const tempFileContract: Contract<TempFile> = {
  extension: isString({ regex: /^\.[a-zA-Z0-9-]/ }),
  fileId: isUlid(),
  uploadId: isUlid(),
  mimeType: isString(),
};

interface TempFileUploadSuccess {
  fieldname: string;
  file: TempFile;
  success: true;
}

interface TempFileUploadFailure {
  success: false;
}

export type TempFileUploadResponse =
  | TempFileUploadSuccess
  | TempFileUploadFailure;
