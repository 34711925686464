import type { Contract } from 'validata';
import {
  asNullable,
  isArray,
  isBoolean,
  isObject,
  isRecord,
  isString,
} from 'validata';
import { isObjectSet } from 'validata-mongo';

export namespace EventSeries {
  export const NAME = 'event-series' as const;

  export interface Type {
    _id: string;
    title: string;
    slug: string;
    slugPattern?: 'country' | 'year' | null;
    country: string;
    organizationIds?: string[] | null;
    groupIds?: string[] | null;
    public: boolean;
    published: boolean;
    year?: number | number[] | null;
    tags: string[];
    labels: Record<string, string>;
    admin?: {
      personIds?: string[] | null;
      organizationIds?: string[] | null;
    } | null;
    starUserIds: string[];
    sortDescending?: boolean | null;
  }

  export type Create = Pick<
    Type,
    | 'title'
    | 'slug'
    | 'country'
    | 'organizationIds'
    | 'groupIds'
    | 'public'
    | 'published'
    | 'tags'
    | 'labels'
  >;
  export type Patch = Pick<
    Type,
    | 'title'
    | 'organizationIds'
    | 'groupIds'
    | 'public'
    | 'published'
    | 'tags'
    | 'labels'
  >;

  // export const geojsonContract: Contract<GeoJSON> = {};

  export const createContract: Contract<Create> = {
    title: isString({ trim: 'both' }),
    slug: isString({ regex: /^[-a-z0-9]+$/, trim: 'both' }),
    country: isString({
      minLength: 2,
      maxLength: 2,
      transform: (value) => value.toUpperCase(),
      trim: 'both',
    }),
    organizationIds: asNullable(isArray(isString({ trim: 'both' }))),
    groupIds: asNullable(isArray(isString({ trim: 'both' }))),
    public: isBoolean(),
    published: isBoolean(),
    tags: isArray(isString({ trim: 'both' })),
    labels: isRecord(isString({ trim: 'both' })),
  };

  export const create = isObject<Create>(createContract);

  export const patchContract: Contract<Patch> = {
    title: isString({ trim: 'both' }),
    organizationIds: asNullable(isArray(isString({ trim: 'both' }))),
    groupIds: asNullable(isArray(isString({ trim: 'both' }))),
    public: isBoolean(),
    published: isBoolean(),
    tags: isArray(isString({ trim: 'both' })),
    labels: isRecord(isString({ trim: 'both' })),
  };

  export const patch = isObjectSet<Patch>(patchContract);
}
