import { isUlid } from '@geee-be/core';
import type { Contract, ValueProcessor } from 'validata';
import {
  asDate,
  asNullable,
  isNumber,
  isObject,
  isString,
  isTuple,
  maybeObject,
} from 'validata';
import { isObjectSet } from 'validata-mongo';
import { TitleEntity } from './common.js';
import type { GeoJSONDataPoint, GeoJSONPoint } from './data-point.js';
import type { Event } from './event.js';

export namespace Track {
  export const NAME = 'track' as const;

  export interface Type extends TitleEntity.Type {
    teamName?: string | null;
    start: GeoJSONDataPoint;
    finish: GeoJSONDataPoint;
    stats: Stats;
    uploaded: Date;
    originalFormat: string;
    compression: string;
    eventId?: string | null;
    courseId?: string | null;
    personId: string;
    skippedFirstControls?: number | null;
    source?: string;
  }

  export interface Stats {
    seconds: number;
    meters?: number | null;
  }

  export type Create = Pick<
    Type,
    | TitleEntity.ModifyProps
    | 'teamName'
    | 'start'
    | 'finish'
    | 'uploaded'
    | 'originalFormat'
    | 'eventId'
    | 'courseId'
    | 'personId'
  >;
  export type Patch = Pick<Type, TitleEntity.ModifyProps | 'teamName'>;

  export const geoJSONDataPointContract: Contract<GeoJSONDataPoint> = {
    when: asDate(),
    location: isObject<GeoJSONPoint>({
      type: isString({ regex: /^Point$/ }) as ValueProcessor<'Point'>,
      coordinates: isTuple<[number, number]>([
        isNumber({ min: -180, max: 180 }),
        isNumber({ min: -90, max: 90 }),
      ]),
    }),
    data: maybeObject(),
  };

  export const createContract: Contract<Create> = {
    teamName: asNullable(isString({ minLength: 2 }), { default: null }),
    start: isObject(geoJSONDataPointContract, { stripExtraProperties: true }),
    finish: isObject(geoJSONDataPointContract, { stripExtraProperties: true }),
    uploaded: asDate(),
    originalFormat: isString(),
    eventId: asNullable(isUlid(), { default: null }),
    courseId: asNullable(isUlid(), { default: null }),
    personId: isUlid(),
    ...TitleEntity.modifyContract,
  };

  export const create = isObject<Create>(createContract);

  export const patchContract: Contract<Patch> = {
    teamName: asNullable(isString({ minLength: 2 }), { default: null }),
    ...TitleEntity.modifyContract,
  };

  export const patch = isObjectSet<Patch>(patchContract);

  export interface UploadResponse {
    filename: string;
    matchingEvents: Event.Type[];
    success: boolean;
    track: Track.Type;
  }
}
