import { maybeUlid } from '@geee-be/core';
import type { Contract, ValueProcessor } from 'validata';
import {
  asBoolean,
  asDate,
  asNullable,
  isArray,
  isNumber,
  isObject,
  isString,
  maybeNumber,
  maybeObject,
  maybeRecord,
  maybeString,
} from 'validata';
import { isObjectSet } from 'validata-mongo';
import { TitleEntity } from './common.js';
import type { Control } from './control.js';
import { Course } from './course.js';
import type { GeoJSONPoint } from './data-point.js';
import type { Corners, TempOverlayImage } from './overlay-image.js';
import { tempOverlayImageContract } from './overlay-image.js';
import type { TempFile } from './temp-file.js';
import { tempFileContract } from './temp-file.js';

export namespace Event {
  export const NAME = 'event' as const;

  export interface Type extends TitleEntity.Type {
    admin?: {
      personIds?: string[] | null;
      organizationIds?: string[] | null;
    } | null;
    close: Date;
    competition?: boolean;
    controls?: Record<string, Control>;
    defaultControlRadius?: number | null;
    eventSeriesId?: string | null;
    finish: Date;
    groupIds?: string[] | null;
    location: GeoJSONPoint;
    organizationIds?: string[] | null;
    overlayImage?: {
      path: string;
      mimeType: string;
      corners: Corners;
    } | null;
    public: boolean;
    published: boolean;
    start: Date;
    starUserIds?: string[] | null;
    type?: string | null;
  }

  export interface WithCourses extends Type {
    courses: Course.WithTrackSummary[];
  }

  export type Create = Pick<
    Type,
    | TitleEntity.ModifyProps
    | 'type'
    | 'organizationIds'
    | 'groupIds'
    | 'public'
    | 'published'
    | 'competition'
    | 'start'
    | 'finish'
    | 'close'
    | 'location'
    | 'controls'
    | 'defaultControlRadius'
    | 'eventSeriesId'
  > & {
    eventFile?: TempFile;
    overlayImage?: TempOverlayImage;
  };

  export type CreateWithCourses = Create & {
    courses: Course.CreateWithoutEventId[];
  };

  export type Patch = Pick<
    Type,
    | TitleEntity.ModifyProps
    | 'type'
    | 'organizationIds'
    | 'groupIds'
    | 'public'
    | 'competition'
    | 'start'
    | 'finish'
    | 'close'
    | 'location'
    | 'controls'
    | 'defaultControlRadius'
    | 'eventSeriesId'
  >;

  // export const geojsonContract: Contract<GeoJSON> = {};

  export const controlContract: Contract<Control> = {
    lat: isNumber({ min: -90, max: 90 }),
    long: isNumber({ min: -180, max: 180 }),
    radius: maybeNumber({ min: 2, max: 100 }),
    altitude: maybeNumber({ min: 0, max: 8000 }),
    points: maybeNumber({ min: 0 }),
    type: maybeString({ regex: /^start|finish$/ }) as ValueProcessor<
      'start' | 'finish'
    >,
  };

  export const createContract: Contract<Create> = {
    type: asNullable(maybeString()),
    organizationIds: asNullable(isArray(isString({ trim: 'both' })), {
      default: null,
    }),
    groupIds: asNullable(isArray(isString({ trim: 'both' })), {
      default: null,
    }),
    public: asBoolean({ default: false }),
    published: asBoolean({ default: false }),
    competition: asBoolean({ default: true }),
    start: asDate(),
    finish: asDate(),
    close: asDate(),
    location: isObject(), // TODO
    controls: maybeRecord(isObject(controlContract)),
    defaultControlRadius: asNullable(maybeNumber()),
    eventSeriesId: asNullable(maybeUlid(), { default: null }),
    ...TitleEntity.modifyContract,
    title: isString({ minLength: 5, trim: 'both' }),
    eventFile: maybeObject(tempFileContract),
    overlayImage: maybeObject(tempOverlayImageContract),
  };

  export const create = isObject<Create>(createContract);

  export const createWithCoursesContract: Contract<CreateWithCourses> = {
    ...createContract,
    courses: isArray(Course.createWithoutEventId),
  };

  export const createWithCourses = isObject<CreateWithCourses>(
    createWithCoursesContract,
  );

  export const patchContract: Contract<Patch> = {
    type: asNullable(maybeString()),
    organizationIds: asNullable(isArray(isString({ trim: 'both' }))),
    groupIds: asNullable(isArray(isString({ trim: 'both' }))),
    public: asBoolean({ default: false }),
    competition: asBoolean({ default: true }),
    start: asDate(),
    finish: asDate(),
    close: asDate(),
    location: isObject(), // TODO
    controls: maybeRecord(isObject(controlContract)),
    defaultControlRadius: asNullable(maybeNumber()),
    eventSeriesId: asNullable(maybeUlid(), { default: null }),
    ...TitleEntity.modifyContract,
    title: isString({ minLength: 5, trim: 'both' }),
  };

  export const patch = isObjectSet<Patch>(patchContract);
}
