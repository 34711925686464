import type { Contract } from 'validata';
import { isArray, isNumber, isObject } from 'validata';
import type { TempFile } from './temp-file.js';
import { tempFileContract } from './temp-file.js';

export declare type Position = [number, number];
export declare type Corners = [Position, Position, Position, Position];

export interface TempOverlayImage {
  image: TempFile;
  corners: Corners;
}

export const tempOverlayImageContract: Contract<TempOverlayImage> = {
  image: isObject(tempFileContract),
  corners: isArray(isArray(isNumber(), { minLength: 2, maxLength: 2 }), {
    minLength: 4,
    maxLength: 4,
  }),
};
