import type { Client, RequestContext } from '@geee-be/core';
import type { Contract } from 'validata';
import { StringFormat, isArray, isObject, isString } from 'validata';
import { isObjectSet } from 'validata-mongo';
import type { BaseEntity } from './common.js';

export namespace Person {
  export const NAME = 'person' as const;

  interface AuthenticationFailure extends Client {
    when: Date;
  }

  interface Identity {
    iss: string;
    sub: string;
  }

  export interface Type extends BaseEntity.Type {
    authenticationFailures?: AuthenticationFailure[];
    disabled?: RequestContext | null;
    email: string;
    verifiedEmails?: string[] | null;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    picture?: string | null;
    lockedOut?: RequestContext | null;
    organizationId?: string | null;
    roles: string[];
    identities: Identity[];
  }

  export type Create = Pick<
    Type,
    'firstName' | 'lastName' | 'fullName' | 'email' | 'roles'
  >;
  export type Patch = Pick<
    Type,
    'firstName' | 'lastName' | 'fullName' | 'email' | 'roles'
  >;

  export const createContract: Contract<Create> = {
    email: isString({ format: StringFormat.email() }),
    firstName: isString({ trim: 'both' }),
    lastName: isString({ trim: 'both' }),
    fullName: isString({ trim: 'both' }),
    roles: isArray(isString({ trim: 'both' })),
  };

  export const create = isObject<Create>(createContract);

  export const patchContract: Contract<Patch> = {
    email: isString({ format: StringFormat.email() }),
    firstName: isString({ trim: 'both' }),
    lastName: isString({ trim: 'both' }),
    fullName: isString({ trim: 'both' }),
    roles: isArray(isString({ trim: 'both' })),
  };

  export const patch = isObjectSet<Patch>(patchContract);
}
