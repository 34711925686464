import type { Contract } from 'validata';
import { isObject } from 'validata';
import { isObjectSet } from 'validata-mongo';
import type { UploadedImage } from './common.js';
import { TitleEntity } from './common.js';

export namespace Organization {
  export const NAME = 'organization' as const;

  export interface Type extends TitleEntity.Type {
    title: string;
    admin?: {
      personIds?: string[] | null;
    } | null;
    logoImage?: UploadedImage.Type | null;
  }

  export const DEFAULTS = {} as const;

  export type Create = Pick<Type, TitleEntity.ModifyProps>;
  export type Patch = Pick<Type, TitleEntity.ModifyProps>;

  export const createContract: Contract<Create> = {
    ...TitleEntity.modifyContract,
  };

  export const create = isObject<Create>(createContract);

  export const patchContract: Contract<Patch> = {
    ...TitleEntity.modifyContract,
  };

  export const patch = isObjectSet<Patch>(patchContract);
}
