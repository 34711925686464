import type { RequestContext } from '@geee-be/core';

export namespace Audit {
  export const NAME = 'audit' as const;

  export interface Type {
    _id: string;
    event: string;
    data?: Record<string, unknown>;
    request: RequestContext;
    userId?: string;
    when: Date;
  }
}
