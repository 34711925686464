import {
  asBoolean,
  isArray,
  isNumber,
  isObject,
  isString,
  maybeNumber,
  StringFormat,
} from 'validata';

export interface TokenPayload {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  naf?: number;
  nbf: number;
  oid?: string;
  rem: boolean;
  rls: string[];
  sid: string;
  sty: string;
  sub: string;
  typ: string;
}

// NOTE: for some reason there is a weird build error if this is used by web
export const checkTokenPayload = isObject<TokenPayload>(
  {
    aud: isString(),
    exp: isNumber(),
    iat: isNumber(),
    iss: isString(),
    jti: isString({ format: StringFormat.ULID() }),
    naf: maybeNumber(),
    nbf: isNumber(),
    oid: isString(),
    rem: asBoolean({ default: false }),
    rls: isArray(isString()),
    sid: isString(),
    sty: isString(),
    sub: isString(),
    typ: isString(),
  },
  { stripExtraProperties: true },
);

export type TokenResponse =
  | {
      success: true;
      accessExpires: Date;
      refreshExpires: Date;
    }
  | { success: false };
