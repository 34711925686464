import type { Contract, ValueProcessor } from 'validata';
import {
  asNullable,
  isArray,
  isObject,
  isRecord,
  isString,
  maybeNumber,
  maybeString,
} from 'validata';
import { isObjectSet } from 'validata-mongo';
import { isUlid } from '../ulid.js';
import { TitleEntity } from './common.js';
import type { CourseControl } from './control.js';

export namespace Course {
  export const NAME = 'course' as const;

  export interface Type extends TitleEntity.Type {
    originalTitle?: string | null;
    eventId: string;
    controls?: CourseControl[];
    defaultControlRadius?: number | null;
  }

  export interface WithTrackSummary extends Type {
    trackCount: number;
    trackPersonIds: string[];
  }

  export type Create = Pick<
    Type,
    TitleEntity.ModifyProps | 'eventId' | 'controls' | 'defaultControlRadius'
  >;
  export type CreateWithoutEventId = Omit<Create, 'eventId'>;
  export type Patch = Pick<
    Type,
    TitleEntity.ModifyProps | 'eventId' | 'controls' | 'defaultControlRadius'
  >;

  export const courseControlContract: Contract<CourseControl> = {
    _id: isString(),
    lat: maybeNumber({ min: -90, max: 90 }),
    long: maybeNumber({ min: -180, max: 180 }),
    type: maybeString({ regex: /^start|finish$/ }) as ValueProcessor<
      'start' | 'finish'
    >,
    radius: maybeNumber({ min: 2, max: 100 }),
    altitude: maybeNumber({ min: 0, max: 8000 }),
  };

  export const createContract: Contract<Create> = {
    title: isString({ trim: 'both' }),
    eventId: isUlid(),
    controls: isArray(isObject(courseControlContract)),
    defaultControlRadius: asNullable(maybeNumber()),
    tags: isArray(isString({ trim: 'both' })),
    labels: isRecord(isString({ trim: 'both' })),
  };

  export const create = isObject<Create>(createContract);

  export const createWithoutEventIdContract: Contract<CreateWithoutEventId> = {
    controls: isArray(isObject(courseControlContract)),
    defaultControlRadius: asNullable(maybeNumber()),
    ...TitleEntity.modifyContract,
  };

  export const createWithoutEventId = isObject<CreateWithoutEventId>(
    createWithoutEventIdContract,
  );

  export const patchContract: Contract<Patch> = {
    eventId: isUlid(),
    controls: isArray(isObject(courseControlContract)),
    defaultControlRadius: asNullable(maybeNumber()),
    ...TitleEntity.modifyContract,
  };

  export const patch = isObjectSet<Patch>(patchContract);
}
