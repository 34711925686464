import type { Client, RequestContext } from '@geee-be/core';

export namespace Session {
  export const NAME = 'session' as const;

  export interface Type extends Client {
    _id: string;
    userId: string;
    issued: Date;
    expires: Date;
    organizationId: string;
    refreshed?: Date | null;
    revoked?: RequestContext | null;
  }
}
