import type { RequestContext } from '@geee-be/core';
import type { Contract, StringFormatCheck } from 'validata';
import { isArray, isRecord, isString } from 'validata';

export namespace BaseEntity {
  export interface Type {
    _id: string;
    created: RequestContext;
    updated: RequestContext;
    tags?: string[] | null;
    labels?: Record<string, string> | null;
  }

  export type ReadonlyProps = '_id' | 'created' | 'updated';
  export type ModifyProps = keyof Omit<Type, ReadonlyProps>;

  export const modifyContract: Contract<Pick<Type, ModifyProps>> = {
    tags: isArray(isString({ trim: 'both' })),
    labels: isRecord(isString({ trim: 'both' })),
  };
}

export namespace TitleEntity {
  export interface Type extends BaseEntity.Type {
    title: string;
  }

  export type ReadonlyProps = BaseEntity.ReadonlyProps;
  export type ModifyProps = keyof Omit<Type, ReadonlyProps>;

  export const modifyContract: Contract<Pick<Type, ModifyProps>> = {
    title: isString({ minLength: 1, trim: 'both' }),
    ...BaseEntity.modifyContract,
  };
}

export namespace UploadedImage {
  export interface Type {
    filename: string;
    contentType: string;
  }

  const filenameFormat: StringFormatCheck = (value) => {
    if (/[-_a-zA-Z0-9 @]+\.[a-zA-Z0-9]+/.test(value)) return true;
    return { expectedFormat: 'filename' };
  };

  const contentTypeFormat: StringFormatCheck = (value) => {
    if (/[-_a-zA-Z0-9]+\/[-_+a-zA-Z0-9]+/.test(value)) return true;
    return { expectedFormat: 'content-type' };
  };

  export const uploadedImageContract: Contract<Type> = {
    filename: isString({ format: filenameFormat, trim: 'both' }),
    contentType: isString({ format: contentTypeFormat, trim: 'both' }),
  };
}
